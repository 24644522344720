/* You can add global styles to this file, and also import other style files */

#tablesaw {
	width: 100%;
	max-width: 100%;
	empty-cells: show;
	border-collapse: collapse;
	border: 0;
	padding: 0;
}
