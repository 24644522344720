.gold-title {
    color: #B8AB69;
    text-align: center;
    font-family: yesevaone, serif;
    font-size: 50px;
    margin: 30px;
    font-weight: normal;
    text-transform: none;
}

.home-title-h4 {
    color: aliceblue;
    text-align: center;
    font-family: yesevaone, serif;
    font-size: 2rem;
    margin: 30px;
    font-weight: normal;
    text-transform: none;
}

.home-title-h1 {
    color: aliceblue !important;
    text-align: center;
    font-family: yesevaone, serif;
    text-transform: uppercase;
    font-size: 6rem;
    margin: 30px;
    font-weight: 400;
}

.title-yellow {
    color: #B8AB69;
    text-align: center;
    font-family: yesevaone, serif;
    font-size: 42px;
    margin: 30px;
    font-weight: normal;
    -webkit-text-stroke: 0.5px #fff;
    //text-shadow: 1px 1px 26px rgba(255,255,255); 
}

.title-yellow-2 {
    color: #B8AB69;
    text-align: center;
    font-family: yesevaone, serif;
    font-size: 32px;
    margin: 30px;
    font-weight: normal; 
    //text-shadow: 1px 1px 26px rgba(255,255,255); 
}

.title-brown {
    color: $top-header;
    text-align: center;
    font-family: yesevaone, serif;
    font-size: 48px;
    margin: 30px;
    font-weight: normal;
}

.text {
    font-family: raleway, serif;
    font-size: 18px;
    color: $font-color;
    margin-bottom: 20px;
    line-height: 30px;
    font-weight: 400;
    text-transform: none;
}

.gold-text {
    font-family: raleway, serif;
    font-size: 18px;
    color: #B8AB69;
    margin-bottom: 20px;
    line-height: 30px;
    font-weight: 400;
    font-weight: bold;
    text-transform: none !important;
}

h2.text-line {
    text-transform: none;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #B8AB69;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

h2.text-line span {
    background: #fff;
    padding: 0 10px;
}

.ornament {
    display: block;
    width: 117px;
    height: 35px;
    background-image: url('../../soragna/ornament.png');
    background-repeat: no-repeat;
    content: " ";
    margin: 0 auto;
}

.section-orario {
    background-image: url('../../soragna/slide3.jpg');

    .section-title-orario {
        h5 {
            color: $white;
            text-align: center;
            font-family: yesevaone, serif;
            text-transform: uppercase;
            font-size: 48px;
            margin: 30px;
            font-weight: normal;
        }
    }

    .text-orario {
        font-family:
            raleway, serif;
        color: $white;
        margin-bottom: 20px;
        line-height: 40px;
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        margin-top: 0px;
    }

    .border-orari {
        border: $tool-yellow solid 1px;
        box-shadow: 0 0 0 0.3rem rgb(243 224 184 / 50%);
        margin: 15px;
    }

}

.slide-title {
    color: $white;
    font-size: 24px;
}

.slide-text {
    color: $tool-yellow;
}

select {
    width: 100%;
    //height: 55%;
    display: block;
    margin-bottom: 20px;
    font-family: $font_0, $font_1;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1rem;
    padding: 6px 12px;
    border: 1px solid #cbc8c8;
    color: $font-color;
    -webkit-appearance: none;
    background-color: white;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.form-check-input:checked {
    padding: 0px;
    box-shadow: 0 0 0 2px $tool-red;
}

.border-contact {
    //border: $top-header solid 1px;
    background-color: $tool-yellow;
    padding: 40px;
    box-shadow: 0 0 0 0.3rem rgb(243 224 184 / 25%);
}

.promotion-slider {
    .promotion-image {
       text-align: end;
    }
    .promotion-text {
        text-align: start;
    }
}

@media(max-width: 767px) {
    .promotion-slider {
        .promotion-image {
           text-align: center;
        }
        .promotion-text {
            text-align: center;
        }
    }

    .gold-title {
        font-size: 38px;
    }

    .title-yellow {
        font-size: 20px;
    }

    .title-brown {
        font-size: 28px;
    }

    .text {
        font-size: 16px;
    }

    .gold-text {
        font-size: 16px;
    }

    h2.text-line {
        text-transform: none;
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #B8AB69;
        line-height: 0.1em;
        margin: 10px 0 20px;
    }

    h2.text-line span {
        background: #fff;
        padding: 0 10px;
    }

    .ornament {
        display: block;
        width: 117px;
        height: 35px;
        background-image: url('../../soragna/ornament.png');
        background-repeat: no-repeat;
        content: " ";
        margin: 0 auto;
    }

    .section-orario {
        background-image: url('../../soragna/slide3.jpg');

        .section-title-orario {
            h5 {
                color: $white;
                text-align: center;
                font-family: yesevaone, serif;
                text-transform: uppercase;
                font-size: 38px;
                margin: 20px;
                font-weight: normal;
                line-height: normal;
            }
        }

        .text-orario {
            font-family:
                raleway, serif;
            color: $white;
            margin-bottom: 20px;
            line-height: 40px;
            font-style: normal;
            font-weight: 400;
            font-size: 40px;
            margin-top: 0px;
        }

        .border-orari {
            border: $tool-yellow solid 1px;
            box-shadow:
                inset 0 0 0 4px $tool-yellow;
            margin: 15px;
        }

    }

    .slide-title {
        color: $white;
        font-size: 24px;
    }

    .slide-text {
        color: $tool-yellow;
    }

    select {
        width: 100%;
        //height: 55%;
        display: block;
        margin-bottom: 20px;
        font-family: $font_0, $font_1;
        font-weight: 400;
        line-height: 1.5;
        font-size: 1rem;
        padding: 6px 12px;
        border: 1px solid #cbc8c8;
        color: #3b3b3b;
        -webkit-appearance: none;
        background-color: white;
        background-clip: padding-box;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }


    /*.form-check-input:checked { 
        padding: 0px;
            box-shadow: 0 0 0 2px $tool-red;
    }*/

    .border-contact {
        border: $top-header solid 2px;
        background-color: $tool-yellow;
        padding: 40px;
    } 
}

.form-check-label {
    div {
        a {
            color: #212529;
            cursor: pointer;
        }
    }
}